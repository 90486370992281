import moment, { Moment } from 'moment';
import {
  cloneDeep,
  flatten,
  groupBy,
  intersection,
  isNil,
  range,
  uniq,
  uniqBy,
} from 'lodash';
import { INormalizeData } from '..';
import { IOperatory } from 'interfaces/useReducerTypes';
import {
  EVENT_COLOR_CODE,
  EXAM_PLACEMENT,
  PMS_EVENT_TYPE,
  SERVICE_NAME,
  SPECIALTY_NAME,
  TIME_GRID_WORKING_HOUR,
} from 'utils/constants';
import { IPMSAppointment } from 'hooks/usePMSAppointment';
import { Specialty } from 'interfaces/specialty';
import { IMapPractitionerColor } from '../utils';
import { DoctorSchedule, PMSClinicSchedule } from 'interfaces/clinicSchedule';
import { IDebugModeDropdown } from '.';
import {
  MINUTE_OF_A_BLOCK,
  calculateBlocks,
  calculateRangeBlock,
  convertBlockNumberToTime,
} from 'utils/getTimeBlockFromTImeString';
import { generateBlockNumbers, groupConsecutiveNumber } from 'utils/common';
import { BOOKING_SPILT_SCHEDULING_TYPE } from 'components/DuplicateAppointment/utils';

export interface IEvent {
  fromTime: string;
  toTime: string;
  patientName: string;
  operatoryName: string;
  colorCode: string;
  practitionerId: string;
  type: string;
  appointmentDetail: IPMSAppointment;
}

export interface EmptyEvent {
  id: string;
  name: string;
  events: [];
  practitioner: IMapPractitionerColor;
}

export interface IPractitionerEvent {
  id: string;
  avatar: string;
  name: string;
  specialties: Specialty[];
  events: IEvent[];
}

export interface IChairEvent {
  id: string;
  name: string;
  events: IEvent[];
}

export const ONE_MINUTE_IN_PX = 2.2;
export const PRACTITIONER_WITH_NO_APPOINTMENT =
  'practitioner_with_no_appointment';
export const PRACTITIONER_WITH_NO_OPERATORY = 'practitioner_with_no_operatory';
export const PRACTITIONER_OFF = 'practitioner_off';
export const NO_APPOINTMENT_ID = 'no_appointment_id';

export const calculateTopPositionByTime = ({
  fromTime,
  toTime,
  extraHeight = 0,
}: {
  fromTime: string;
  toTime: string;
  extraHeight?: number;
}) => {
  const minutes = moment(toTime, 'HH:mm:ss').diff(
    moment(fromTime, 'HH:mm:ss'),
    'minutes'
  );

  const value = minutes * ONE_MINUTE_IN_PX + extraHeight;

  return value;
};

export const normalizedPractitionerEvents = (
  data: INormalizeData[]
): IPractitionerEvent[] => {
  return data.map((practitioner) => {
    return {
      id: practitioner.id,
      avatar: practitioner.avatar,
      name: practitioner.name,
      specialties: practitioner.specialties,
      events: practitioner.appointments.map((appt) => {
        return {
          appointmentDetail: { ...appt },
          fromTime: appt.startTime,
          toTime: appt.endTime,
          patientName: appt.patient?.name || '',
          operatoryName: appt.operatory?.name || '',
          colorCode: practitioner.colorCode,
          practitionerId: practitioner.id,
          type: PMS_EVENT_TYPE.PMS_PRACTITIONER_HOURS,
          errorReason: appt.errorReason,
          pmsType: appt.pmsType,
        };
      }),
    };
  });
};

export const normalizedChairEvents = (
  data: INormalizeData[],
  operatories: IOperatory[]
): IChairEvent[] => {
  return operatories.map((op) => {
    const practitionersByOp = data.filter((item) =>
      item.appointments.some((appt) => appt.operatory?.id === op.id)
    );

    const appointmentsByOp = practitionersByOp.reduce(
      (accum: any, currentPractitioner) => {
        const normalizedAppts = currentPractitioner.appointments
          .filter((appt) => appt.operatory?.id === op.id)
          .map((appt) => {
            return {
              appointmentDetail: { ...appt },
              fromTime: appt.startTime,
              toTime: appt.endTime,
              patientName: appt.patient?.name || '',
              operatoryName: appt.operatory?.name || '',
              colorCode: currentPractitioner.colorCode,
              practitionerId: currentPractitioner.id,
              type: PMS_EVENT_TYPE.PMS_PRACTITIONER_HOURS,
              errorReason: appt.errorReason,
              pmsType: appt.pmsType,
            };
          });

        accum = accum.concat(normalizedAppts);

        return accum;
      },
      []
    );

    return {
      id: op.id,
      name: op.name,
      events: appointmentsByOp,
    };
  });
};

export const normalizedUnavailableEvents = (
  chairEvents: IChairEvent[],
  unavailableAppointments: IPMSAppointment[],
  isDebugMode: boolean
) => {
  const normalizedUnavailableEvents = chairEvents.map((item) => {
    const events = unavailableAppointments
      .filter((unavailableAppt) => unavailableAppt.operatory?.id === item.id)
      .map((unavailableAppt) => {
        return {
          fromTime:
            unavailableAppt.startTime === '00:00:00'
              ? TIME_GRID_WORKING_HOUR.START
              : unavailableAppt.startTime, //00:00:00 from BE response
          toTime:
            unavailableAppt.endTime === '00:00:00'
              ? TIME_GRID_WORKING_HOUR.END
              : unavailableAppt.endTime,
          patientName: '',
          operatoryName: unavailableAppt.operatory.name,
          colorCode: isDebugMode ? '' : EVENT_COLOR_CODE,
          practitionerId: '',
          type: PMS_EVENT_TYPE.EVENT,
          appointmentDetail: { ...unavailableAppt },
        } as any;
      });

    return {
      ...item,
      events: [...item.events, ...events],
    };
  });

  return normalizedUnavailableEvents;
};

export const groupByOverlappedEvents = (events: IEvent[]) => {
  let result = [];
  let tmpOverlappedIdx: number[] = [];

  let filteredNormalEvents = events.filter(
    (item) =>
      item.type === PMS_EVENT_TYPE.PMS_PRACTITIONER_HOURS ||
      item.type === PMS_EVENT_TYPE.AVAILABLE_EVENT
  );

  for (let i = 0; i < filteredNormalEvents.length; i++) {
    const { fromTime, toTime } = filteredNormalEvents[i];

    let eventGroup: IEvent[] = [];
    if (!tmpOverlappedIdx.includes(i)) {
      eventGroup.push(filteredNormalEvents[i]);
      tmpOverlappedIdx.push(i);
    }

    for (let j = 0; j < filteredNormalEvents.length; j++) {
      if (i === j || tmpOverlappedIdx.includes(j)) {
        continue;
      }

      const isBetween = moment(
        filteredNormalEvents[j].fromTime,
        'HH:mm:ss'
      ).isBetween(
        moment(fromTime, 'HH:mm:ss'),
        moment(toTime, 'HH:mm:ss'),
        undefined,
        '[)'
      );

      if (isBetween) {
        eventGroup.push(filteredNormalEvents[j]);
        tmpOverlappedIdx.push(j);
      }
    }

    if (eventGroup.length !== 0) {
      result.push(eventGroup);
    }
  }

  let filteredNonNormalEvents = events.filter(
    (item) =>
      ![
        PMS_EVENT_TYPE.PMS_PRACTITIONER_HOURS,
        PMS_EVENT_TYPE.AVAILABLE_EVENT,
      ].includes(item.type)
  );
  for (let i = 0; i < filteredNonNormalEvents.length; i++) {
    result.push([filteredNonNormalEvents[i]]);
  }

  return result;
};

export const isSelectedEventAvailable = (
  selectedTime: { fromTime: string; toTime: string },
  event: { fromTime: string; toTime: string }
) => {
  const isAvailableEventOverlapOtherEvent =
    moment(selectedTime.fromTime, 'HH:mm:ss').isSameOrBefore(
      moment(event.fromTime, 'HH:mm:ss'),
      'minutes'
    ) &&
    moment(selectedTime.toTime, 'HH:mm:ss').isSameOrAfter(
      moment(event.toTime, 'HH:mm:ss'),
      'minutes'
    );

  if (isAvailableEventOverlapOtherEvent) {
    return false;
  }

  const isAvailableEventInsideOtherEvent =
    moment(selectedTime.fromTime, 'HH:mm:ss').isBetween(
      moment(event.fromTime, 'HH:mm:ss'),
      moment(event.toTime, 'HH:mm:ss'),
      'minutes',
      '()'
    ) ||
    moment(selectedTime.toTime, 'HH:mm:ss').isBetween(
      moment(event.fromTime, 'HH:mm:ss'),
      moment(event.toTime, 'HH:mm:ss'),
      'minutes',
      '()'
    );

  if (isAvailableEventInsideOtherEvent) {
    return false;
  }

  return true;
};

//--------------Functions To Calculate Block to Render Black Rectangles----------------
// Calculate unavailable block by Op not Assign to selected practitioner
export const blockUnavailableByOpNotAssignedToSelectedPractitioners = ({
  selectedPractitioners,
  pmsClinicSchedule,
  clinicWorkingHour,
  timeGridData,
}: {
  selectedPractitioners: IDebugModeDropdown['practitioners'];
  pmsClinicSchedule?: PMSClinicSchedule;
  clinicWorkingHour: { startTime: string; endTime: string };
  timeGridData: {
    events: {
      fromTime: string;
      toTime: string;
      patientName: string;
      operatoryName: string;
      colorCode: string;
      practitionerId: string;
      type: string;
    }[];
    id: string;
    name: string;
  }[];
}) => {
  const selectedDoctorIds = selectedPractitioners.map((doctor) => doctor.id);

  const operatoriesBySelectedDoctor = pmsClinicSchedule?.doctors
    .filter((doctor) => selectedDoctorIds.includes(doctor.id))
    .reduce((accum: any, doctor: any) => {
      const operatoryIds = doctor.operatories.map(
        (op: any) => op.id
      ) as string[];

      accum.push(...operatoryIds);

      return uniq(accum);
    }, []);

  const result = timeGridData.map((item) => {
    const operatoryId = item.id;

    if (
      operatoriesBySelectedDoctor &&
      !operatoriesBySelectedDoctor.includes(operatoryId)
    ) {
      item.events.push({
        fromTime: clinicWorkingHour.startTime,
        toTime: clinicWorkingHour.endTime,
        patientName: '',
        operatoryName: '',
        colorCode: '',
        practitionerId: '',
        type: PMS_EVENT_TYPE.BLOCKED,
      });
    }

    return {
      ...item,
    };
  });

  return result;
};

// Calculate doctor working day. Return unavailable block if they are outside of doctor working day
const calculateDoctorWorkingDayEvents = ({
  clinicWorkingHour,
  pmsClinicSchedule,
  selectedDoctorIds,
}: {
  clinicWorkingHour: any;
  pmsClinicSchedule?: PMSClinicSchedule;
  selectedDoctorIds: string[];
}): {
  fromTime: string;
  toTime: string;
  practitionerId: string;
}[] => {
  const practitioners = pmsClinicSchedule?.doctors || [];

  const blockEvents = selectedDoctorIds.reduce(
    (accum: any, selectedDoctorId: string) => {
      const workingHourByPractitioner = practitioners.find(
        (item: any) => item.id === selectedDoctorId
      )?.workingHour;

      if (workingHourByPractitioner?.start && workingHourByPractitioner.end) {
        const isClinicStartTimeBeforePractitioner = moment(
          clinicWorkingHour.startTime,
          'HH:mm:ss'
        ).isBefore(
          moment(workingHourByPractitioner.start, 'HH:mm:ss'),
          'minutes'
        );

        if (isClinicStartTimeBeforePractitioner) {
          accum.push({
            fromTime: clinicWorkingHour.startTime,
            toTime: workingHourByPractitioner.start,
            practitionerId: selectedDoctorId,
          });
        }

        const isClinicEndTimeAfterPractitioner = moment(
          clinicWorkingHour.endTime,
          'HH:mm:ss'
        ).isAfter(moment(workingHourByPractitioner.end, 'HH:mm:ss'), 'minutes');

        if (isClinicEndTimeAfterPractitioner) {
          accum.push({
            fromTime: workingHourByPractitioner.end,
            toTime: clinicWorkingHour.endTime,
            practitionerId: selectedDoctorId,
          });
        }
      }

      return accum;
    },
    []
  );

  return blockEvents;
};

// For example:
// doctor A: [1,2,3], doctor B: [1, 2] => [1,2]
const calculateWorkingDayUnavailableBlockNumberIntersectionForDoctors = ({
  doctorIds,
  unavailableWorkingDaysByDoctor,
}: {
  doctorIds: string[];
  unavailableWorkingDaysByDoctor: {
    fromTime: string;
    toTime: string;
    practitionerId: string;
  }[];
}): number[] => {
  let unavailableBlockNumbers: number[] = [];
  let tmp = [];

  for (let i = 0; i < doctorIds.length; i++) {
    let unavailableBlockNumbersByDoctor: number[] = [];

    const tmp1 = unavailableWorkingDaysByDoctor.filter(
      (item) => item.practitionerId === doctorIds[i]
    );

    for (let j = 0; j < tmp1.length; j++) {
      const tmpUnavailableBlockNumbers = calculateRangeBlock(
        tmp1[j].fromTime,
        tmp1[j].toTime
      );

      unavailableBlockNumbersByDoctor = unavailableBlockNumbersByDoctor.concat(
        tmpUnavailableBlockNumbers
      );
    }

    tmp.push({
      doctorId: doctorIds[i],
      unavailableBlockNumbersByDoctor,
    });
  }

  const unavailableBlockNumbersByDoctors: number[][] = tmp.map(
    (item) => item.unavailableBlockNumbersByDoctor
  );

  unavailableBlockNumbers = intersection(...unavailableBlockNumbersByDoctors);

  return unavailableBlockNumbers;
};

// Calculate unavailable block based on doctor working hour
const calculateUnavailableBlockEvent = ({
  doctorIds,
  unavailableWorkingDaysByDoctor,
}: {
  doctorIds: string[];
  unavailableWorkingDaysByDoctor: {
    fromTime: string;
    toTime: string;
    practitionerId: string;
  }[];
}) => {
  const unavailableBlockNumbersIntersection =
    calculateWorkingDayUnavailableBlockNumberIntersectionForDoctors({
      doctorIds,
      unavailableWorkingDaysByDoctor,
    });

  const consecutiveNumbers = groupConsecutiveNumber(
    unavailableBlockNumbersIntersection
  );

  const result = consecutiveNumbers.reduce(
    (
      accum: {
        fromTime: string;
        toTime: string;
        patientName: string;
        operatoryName: string;
        colorCode: string;
        practitionerId: string;
        type: string;
      }[],
      currentValue: number[]
    ) => {
      accum.push({
        fromTime: convertBlockNumberToTime(currentValue[0]),
        toTime:
          currentValue.length > 1
            ? convertBlockNumberToTime(
                currentValue[currentValue.length - 1] + 1
              )
            : convertBlockNumberToTime(currentValue[0] + 1),
        patientName: '',
        operatoryName: '',
        colorCode: '',
        practitionerId: '',
        type: PMS_EVENT_TYPE.BLOCKED,
      });

      return accum;
    },
    []
  );

  return result;
};

export const blockUnavailableByDoctorWorkingHour = ({
  selectedPractitioners,
  pmsClinicSchedule,
  clinicWorkingHour,
  timeGridData,
}: {
  selectedPractitioners: IDebugModeDropdown['practitioners'];
  pmsClinicSchedule?: PMSClinicSchedule;
  clinicWorkingHour: { startTime: string; endTime: string };
  timeGridData: {
    events: {
      fromTime: string;
      toTime: string;
      patientName: string;
      operatoryName: string;
      colorCode: string;
      practitionerId: string;
      type: string;
    }[];
    id: string;
    name: string;
  }[];
}) => {
  const selectedDoctorIds = selectedPractitioners.map((doctor) => doctor.id);

  const selectedDoctorWithOp =
    pmsClinicSchedule?.doctors
      .filter((doctor) => selectedDoctorIds.includes(doctor.id))
      .reduce(
        (
          accum: { practitionerId: string; operatoryIds: string[] }[],
          doctor: any
        ) => {
          const operatoryIds = doctor.operatories.map(
            (op: any) => op.id
          ) as string[];

          accum.push({
            practitionerId: doctor.id,
            operatoryIds,
          });

          return accum;
        },
        []
      ) || [];

  const blockEvents = calculateDoctorWorkingDayEvents({
    clinicWorkingHour,
    pmsClinicSchedule,
    selectedDoctorIds,
  });

  const result = timeGridData.map((item: any) => {
    const operatoryId = item.id;

    const doctorIds = selectedDoctorWithOp
      .filter((item2) => item2.operatoryIds.includes(operatoryId))
      .map((item2) => item2.practitionerId);

    const unavailableBlockEvents = calculateUnavailableBlockEvent({
      doctorIds,
      unavailableWorkingDaysByDoctor: blockEvents,
    });

    item.events = item.events.concat(unavailableBlockEvents);

    return {
      ...item,
    };
  });

  return result;
};

export const blockUnavailableByAppts = ({
  selectedPractitioners,
  pmsClinicSchedule,
  timeGridData,
}: {
  selectedPractitioners: IDebugModeDropdown['practitioners'];
  pmsClinicSchedule?: PMSClinicSchedule;
  timeGridData: {
    events: {
      fromTime: string;
      toTime: string;
      patientName: string;
      operatoryName: string;
      colorCode: string;
      practitionerId: string;
      type: string;
    }[];
    id: string;
    name: string;
  }[];
}) => {
  const selectedDoctorIds = selectedPractitioners.map((doctor) => doctor.id);

  const selectedDoctorWithOp =
    pmsClinicSchedule?.doctors
      .filter((doctor) => selectedDoctorIds.includes(doctor.id))
      .reduce(
        (
          accum: { practitionerId: string; operatoryIds: string[] }[],
          doctor: any
        ) => {
          const operatoryIds = doctor.operatories.map(
            (op: any) => op.id
          ) as string[];

          accum.push({
            practitionerId: doctor.id,
            operatoryIds,
          });

          return accum;
        },
        []
      ) || [];

  const unavailableBlockNumberByAppt = calculateUnavailableBlockNumberByAppt({
    timeGridData,
    selectedDoctorIds,
  }).map((item) => ({
    name: item.name,
    operatoryId: item.operatoryId,
    practitionerId: item.practitionerId,
    fromTime: convertBlockNumberToTime(item.blockNumbers[0]),
    toTime: convertBlockNumberToTime(
      item.blockNumbers[item.blockNumbers.length - 1] + 1
    ),
  }));

  const result = timeGridData.map((item: any) => {
    const operatoryId = item.id;

    const doctorIds = selectedDoctorWithOp
      .filter((item2) => item2.operatoryIds.includes(operatoryId))
      .map((item2) => item2.practitionerId);

    const blockEvents = unavailableBlockNumberByAppt.filter((item2) =>
      doctorIds.includes(item2.practitionerId)
    );

    const unavailableBlockEvents = calculateUnavailableBlockEvent({
      doctorIds,
      unavailableWorkingDaysByDoctor: blockEvents,
    });

    item.events = item.events.concat(unavailableBlockEvents);

    return {
      ...item,
    };
  });

  return result;
};

//--------Functions for blockUnavailableByNotEnoughDuration--------
const calculateRemainingAvailableBlockNumberByOp = ({
  timeGridData,
  availableTimeBlockNumbers,
}: any) => {
  let availableBlockNumbersByOp: {
    operatoryId: string;
    name: string;
    availableTimeBlockNumbers: number[];
  }[] = [];

  for (let i = 0; i < timeGridData.length; i++) {
    const events = timeGridData[i].events;
    let unavailableTimeBlockNumbers: number[] = [];

    for (let j = 0; j < events.length; j++) {
      const event = events[j];

      const blockNumbers = calculateRangeBlock(event.fromTime, event.toTime);

      unavailableTimeBlockNumbers = uniq(
        unavailableTimeBlockNumbers.concat(blockNumbers)
      );
    }

    const remainingAvailableTimeBlockNumbers = availableTimeBlockNumbers.filter(
      (item: number) => {
        return !unavailableTimeBlockNumbers.includes(item);
      }
    );

    availableBlockNumbersByOp.push({
      operatoryId: timeGridData[i].id,
      name: timeGridData[i].name,
      availableTimeBlockNumbers: remainingAvailableTimeBlockNumbers,
    });
  }

  return availableBlockNumbersByOp;
};

const calculateUnavailableBlockNumberByAppt = ({
  timeGridData,
  selectedDoctorIds,
}: any) => {
  let appointments: {
    operatoryId: string;
    name: string;
    practitionerId: string;
    blockNumbers: number[];
  }[] = [];

  for (let i = 0; i < timeGridData.length; i++) {
    const events = timeGridData[i].events;

    for (let j = 0; j < events.length; j++) {
      const event = events[j];

      if (
        event.type === PMS_EVENT_TYPE.PMS_PRACTITIONER_HOURS &&
        selectedDoctorIds.includes(event.practitionerId)
      ) {
        appointments.push({
          operatoryId: timeGridData[i].id,
          name: timeGridData[i].name,
          practitionerId: event.practitionerId,
          blockNumbers: calculateRangeBlock(event.fromTime, event.toTime),
        });
      }
    }
  }

  return appointments;
};

const calculateUnavailableBlockNumberByEvent = ({
  timeGridData,
  selectedDoctorIds,
  pmsClinicSchedule,
}: {
  timeGridData: any;
  selectedDoctorIds: string[];
  pmsClinicSchedule?: PMSClinicSchedule;
}) => {
  let events: {
    operatoryId: string;
    name: string;
    practitionerId: string;
    blockNumbers: number[];
  }[] = [];

  const selectedDoctorsWithOp =
    pmsClinicSchedule?.doctors.filter((doctor: any) =>
      selectedDoctorIds.includes(doctor.id)
    ) || [];

  for (let i = 0; i < timeGridData.length; i++) {
    const operatoryId = timeGridData[i].id;

    const isOpAssignToSelectedDoctor = selectedDoctorsWithOp.some((item) =>
      item.operatories.some((op) => op.id === operatoryId)
    );

    const tmpEvents = timeGridData[i].events;

    for (let j = 0; j < tmpEvents.length; j++) {
      const event = tmpEvents[j];

      if (event.type === PMS_EVENT_TYPE.EVENT && isOpAssignToSelectedDoctor) {
        events.push({
          operatoryId: timeGridData[i].id,
          name: timeGridData[i].name,
          practitionerId: '',
          blockNumbers: calculateRangeBlock(event.fromTime, event.toTime),
        });
      }
    }
  }

  return events;
};

const calculateUnavailableBlockNumberByBlocked = ({
  timeGridData,
  selectedDoctorIds,
  pmsClinicSchedule,
}: {
  timeGridData: any;
  selectedDoctorIds: string[];
  pmsClinicSchedule?: PMSClinicSchedule;
}) => {
  let events: {
    operatoryId: string;
    name: string;
    practitionerId: string;
    blockNumbers: number[];
  }[] = [];

  const selectedDoctorsWithOp =
    pmsClinicSchedule?.doctors.filter((doctor: any) =>
      selectedDoctorIds.includes(doctor.id)
    ) || [];

  for (let i = 0; i < timeGridData.length; i++) {
    const operatoryId = timeGridData[i].id;

    const isOpAssignToSelectedDoctor = selectedDoctorsWithOp.some((item) =>
      item.operatories.some((op) => op.id === operatoryId)
    );

    const tmpEvents = timeGridData[i].events;

    for (let j = 0; j < tmpEvents.length; j++) {
      const event = tmpEvents[j];

      if (event.type === PMS_EVENT_TYPE.BLOCKED && isOpAssignToSelectedDoctor) {
        events.push({
          operatoryId: timeGridData[i].id,
          name: timeGridData[i].name,
          practitionerId: '',
          blockNumbers: calculateRangeBlock(event.fromTime, event.toTime),
        });
      }
    }
  }

  return events;
};

const checkRangeBlocksNotAvailableForAllDoctors = ({
  rangeBlocks,
  selectedDoctorsByOp,
  workingHourBlockEvents,
  unavailableBlockNumbersByApptsByDoctor,
}: {
  rangeBlocks: number[];
  selectedDoctorsByOp: DoctorSchedule[];
  workingHourBlockEvents: {
    fromTime: string;
    toTime: string;
    practitionerId: string;
  }[];
  unavailableBlockNumbersByApptsByDoctor: {
    blockNumbers: number[];
    name: string;
    operatoryId: string;
    practitionerId: string;
  }[];
}) => {
  let totalAvailableDoctors = selectedDoctorsByOp.length;

  for (let i = 0; i < selectedDoctorsByOp.length; i++) {
    // Check appt
    const dr = selectedDoctorsByOp[i];

    const unavailableBlockNumbersByAppsByDr =
      unavailableBlockNumbersByApptsByDoctor
        .filter((appt) => appt.practitionerId === dr.id)
        .map((appt) => appt.blockNumbers);

    const uniqUnavailableBlockNumbers = uniq(
      flatten(unavailableBlockNumbersByAppsByDr)
    );

    const isBlockNumberExistingInAppt = rangeBlocks.some((block) =>
      uniqUnavailableBlockNumbers.includes(block)
    );

    // Check block number by outside working hour
    const unavailableBlockNumbersByOutsideWorkingByDr = workingHourBlockEvents
      .filter((item) => item.practitionerId === dr.id)
      .map((item) => calculateRangeBlock(item.fromTime, item.toTime));

    const uniqUnavailableBlockNumbersByOutsideWorkingByDr = uniq(
      flatten(unavailableBlockNumbersByOutsideWorkingByDr)
    );

    const isBlockNumberBelongOutsideWorkingHour = rangeBlocks.some((block) =>
      uniqUnavailableBlockNumbersByOutsideWorkingByDr.includes(block)
    );

    if (isBlockNumberExistingInAppt || isBlockNumberBelongOutsideWorkingHour) {
      totalAvailableDoctors -= 1;
    }
  }

  return totalAvailableDoctors === 0;
};

const filterUnavailableBlockNumberByServiceDurationAndAppt = ({
  serviceDuration,
  clinicWorkingHour,
  availableBlockNumbersByOp,
  unavailableBlockNumbersByAppt,
  selectedDoctorIds,
  pmsClinicSchedule,
}: {
  serviceDuration: number;
  clinicWorkingHour: any;
  availableBlockNumbersByOp: any;
  unavailableBlockNumbersByAppt: {
    blockNumbers: number[];
    name: string;
    operatoryId: string;
    practitionerId: string;
  }[];
  selectedDoctorIds: string[];
  pmsClinicSchedule?: PMSClinicSchedule;
}) => {
  const selectedDoctorsWithOp =
    pmsClinicSchedule?.doctors.filter((doctor: any) =>
      selectedDoctorIds.includes(doctor.id)
    ) || [];

  if (selectedDoctorsWithOp.length === 0) {
    return [];
  }

  const workingHourBlockEvents = calculateDoctorWorkingDayEvents({
    clinicWorkingHour,
    pmsClinicSchedule,
    selectedDoctorIds,
  });

  const filteredUnavailableBlockNumbersByOp = availableBlockNumbersByOp.reduce(
    (
      accum: {
        operatoryId: string;
        name: string;
        unavailableTimeBlockNumbers: number[];
      }[],
      currentValue: any
    ) => {
      const operatoryId = currentValue.operatoryId;

      const availableBlockNumbers = currentValue.availableTimeBlockNumbers;

      let tmpServiceDuration = serviceDuration;

      // Check operatory if it only includes dentist and selected service is split, only get dentist duration
      const filterSelectedDoctorsByOp = selectedDoctorsWithOp.filter((item) =>
        item.operatories.some((item2) => item2.id === operatoryId)
      );

      if (filterSelectedDoctorsByOp.length === 0) {
        return accum;
      }

      const unavailableBlockNumbersByApptsByDoctor =
        unavailableBlockNumbersByAppt.filter((item) =>
          filterSelectedDoctorsByOp.some(
            (item2) => item2.id === item.practitionerId
          )
        );

      const filterUnavailableBlockNumber = availableBlockNumbers.filter(
        (blockNumber: number) => {
          const rangeBlocks = range(
            blockNumber,
            blockNumber + tmpServiceDuration
          );

          const isIncludedInAvailableBlockNumbers = rangeBlocks.every((block) =>
            availableBlockNumbers.includes(block)
          );

          const isBlockNumberBelongAppt = filterSelectedDoctorsByOp.every(
            (dr) => {
              const unavailableBlockNumbersByAppsByDr =
                unavailableBlockNumbersByApptsByDoctor
                  .filter((appt) => appt.practitionerId === dr.id)
                  .map((appt) => appt.blockNumbers);

              const uniqUnavailableBlockNumbers = uniq(
                flatten(unavailableBlockNumbersByAppsByDr)
              );

              const isBlockNumberExistingInAppt = rangeBlocks.some((block) =>
                uniqUnavailableBlockNumbers.includes(block)
              );

              return isBlockNumberExistingInAppt;
            }
          );

          const isBlockNumberNotAvailableForAllDoctors =
            checkRangeBlocksNotAvailableForAllDoctors({
              rangeBlocks,
              selectedDoctorsByOp: filterSelectedDoctorsByOp,
              workingHourBlockEvents,
              unavailableBlockNumbersByApptsByDoctor,
            });

          return (
            !isIncludedInAvailableBlockNumbers ||
            isBlockNumberBelongAppt ||
            isBlockNumberNotAvailableForAllDoctors
          );
        }
      );

      accum.push({
        operatoryId,
        name: currentValue.name,
        unavailableTimeBlockNumbers: filterUnavailableBlockNumber,
      });

      return accum;
    },
    []
  );

  return filteredUnavailableBlockNumbersByOp;
};

export const blockUnavailableByNotEnoughDuration = ({
  selectedPractitioners,
  selectedService,
  clinicWorkingHour,
  timeGridData,
  pmsClinicSchedule,
}: {
  selectedPractitioners: IDebugModeDropdown['practitioners'];
  selectedService: {
    id: string;
    isSplitScheduling: boolean;
    hygienistDuration?: number;
    doctorDuration?: number;
    duration: number;
    name: string;
  };
  clinicWorkingHour: { startTime: string; endTime: string };
  timeGridData: {
    events: {
      fromTime: string;
      toTime: string;
      patientName: string;
      operatoryName: string;
      colorCode: string;
      practitionerId: string;
      type: string;
    }[];
    id: string;
    name: string;
  }[];
  pmsClinicSchedule?: PMSClinicSchedule;
}) => {
  const selectedDoctorIds = selectedPractitioners.map((doctor) => doctor.id);

  const availableTimeBlockNumbers: number[] = calculateRangeBlock(
    clinicWorkingHour.startTime,
    clinicWorkingHour.endTime
  );

  let availableBlockNumbersByOp = calculateRemainingAvailableBlockNumberByOp({
    timeGridData: cloneDeep(timeGridData),
    availableTimeBlockNumbers,
  });

  let unavailableBlockNumbersByAppt = calculateUnavailableBlockNumberByAppt({
    timeGridData: cloneDeep(timeGridData),
    selectedDoctorIds,
  });

  const filteredUnavailableBlockNumber =
    filterUnavailableBlockNumberByServiceDurationAndAppt({
      serviceDuration: selectedService.isSplitScheduling
        ? selectedService.hygienistDuration!
        : selectedService.duration,
      clinicWorkingHour,
      availableBlockNumbersByOp,
      unavailableBlockNumbersByAppt,
      selectedDoctorIds,
      pmsClinicSchedule,
    });

  const result = timeGridData.map((item) => {
    const operatoryId = item.id;

    const unavailableTimeBlockNumbersByOp =
      filteredUnavailableBlockNumber.find(
        (item2: any) => item2.operatoryId === operatoryId
      )?.unavailableTimeBlockNumbers || [];

    if (unavailableTimeBlockNumbersByOp.length === 0) {
      return { ...item };
    }

    const unavailableTimeBlockByGroup = groupConsecutiveNumber(
      unavailableTimeBlockNumbersByOp
    );

    const blockEvents = unavailableTimeBlockByGroup.map((item3: number[]) => {
      let fromTime = item3[0];
      let toTime = item3[0] + 1;

      if (item3.length > 1) {
        toTime = item3[item3.length - 1] + 1;
      }

      return {
        fromTime: convertBlockNumberToTime(fromTime),
        toTime: convertBlockNumberToTime(toTime),
        patientName: '',
        operatoryName: item.name,
        colorCode: '',
        practitionerId: '',
        type: PMS_EVENT_TYPE.BLOCKED,
      };
    });

    item.events = item.events.concat(blockEvents);

    return { ...item };
  });

  return result;
};

//--------Functions for filterWorkingPractitionerForExamAndCleaning--------
const splitDentistAvailabilityByHeadOrTail = ({
  practitionerId,
  operatoryId,
  dentistAvailableBlockNumbersByBackToBack,
  hygienistAvailability,
  type,
  dentistDuration,
  dentistWithOp,
}: {
  practitionerId: string;
  operatoryId: string;
  dentistAvailableBlockNumbersByBackToBack: {
    isHead: boolean;
    isTail: boolean;
    availableBlockNumbers: number[];
  }[];
  hygienistAvailability: {
    operatoryId: string;
    fromTime: string;
    toTime: string;
  };
  type:
    | typeof BOOKING_SPILT_SCHEDULING_TYPE.HEAD
    | typeof BOOKING_SPILT_SCHEDULING_TYPE.TAIL;
  dentistDuration: number;
  dentistWithOp: DoctorSchedule | null;
}) => {
  let result = [];

  const list = dentistAvailableBlockNumbersByBackToBack.filter((item) => {
    if (type === BOOKING_SPILT_SCHEDULING_TYPE.HEAD) {
      return item.isHead;
    } else if (type === BOOKING_SPILT_SCHEDULING_TYPE.TAIL) {
      return item.isTail;
    }

    return false;
  });

  const workingHourRangeBlocks = calculateRangeBlock(
    dentistWithOp?.workingHour.start!,
    dentistWithOp?.workingHour.end!
  );

  for (let i = 0; i < list.length; i++) {
    const item = list[i];

    const availableBlockNumbers = item.availableBlockNumbers;

    const fromTimeBlockNumber =
      type === BOOKING_SPILT_SCHEDULING_TYPE.HEAD
        ? availableBlockNumbers[availableBlockNumbers.length - 1] -
          (dentistDuration - 1)
        : availableBlockNumbers[0];

    const toTimeBlockNumber =
      type === BOOKING_SPILT_SCHEDULING_TYPE.HEAD
        ? availableBlockNumbers[availableBlockNumbers.length - 1] + 1
        : availableBlockNumbers[0] + dentistDuration;

    const isHeadBackToBackCondition =
      item.isHead &&
      toTimeBlockNumber === calculateBlocks(hygienistAvailability.fromTime);

    const isTailBackToBackCondition =
      item.isTail &&
      fromTimeBlockNumber === calculateBlocks(hygienistAvailability.toTime);

    // Check inside working hour
    const tmpRangeBlocks = calculateRangeBlock(
      convertBlockNumberToTime(fromTimeBlockNumber),
      convertBlockNumberToTime(toTimeBlockNumber)
    );

    const isBlockNumberOutSideWorkingHour = tmpRangeBlocks.some(
      (item2) => !workingHourRangeBlocks.includes(item2)
    );

    if (
      !isBlockNumberOutSideWorkingHour &&
      (isHeadBackToBackCondition || isTailBackToBackCondition)
    ) {
      result.push({
        practitionerId,
        operatoryId,
        fromTime: convertBlockNumberToTime(fromTimeBlockNumber),
        toTime: convertBlockNumberToTime(toTimeBlockNumber),
      });
    }
  }

  return result;
};

export const calculateDentistAvailabilities = ({
  hygienistAvailability,
  clinicWorkingHour,
  dentistsWithOp,
  selectedService,
  timeGridData,
  examPlacement,
}: {
  hygienistAvailability: {
    operatoryId: string;
    fromTime: string;
    toTime: string;
  };
  clinicWorkingHour: { startTime: string; endTime: string };
  dentistsWithOp: DoctorSchedule[];
  selectedService: {
    id: string;
    isSplitScheduling: boolean;
    hygienistDuration?: number;
    doctorDuration?: number;
    duration: number;
    name: string;
  };
  timeGridData: {
    events: {
      fromTime: string;
      toTime: string;
      patientName: string;
      operatoryName: string;
      colorCode: string;
      practitionerId: string;
      type: string;
    }[];
    id: string;
    name: string;
  }[];
  examPlacement?: string | null;
}) => {
  const dentistDuration = selectedService.doctorDuration;

  let result: any = [];

  if (isNil(dentistDuration) || dentistDuration === 0) {
    return result;
  }

  const availableBlockNumbersByClinicWorkingHour = calculateRangeBlock(
    clinicWorkingHour.startTime,
    clinicWorkingHour.endTime
  );

  const blockNumbersByHygienistAvailability = calculateRangeBlock(
    hygienistAvailability.fromTime,
    hygienistAvailability.toTime
  );

  result = dentistsWithOp.reduce((accum: any, currentDentist) => {
    for (let i = 0; i < currentDentist.operatories.length; i++) {
      const operatoryId = currentDentist.operatories[i].id;

      const eventsByOp =
        timeGridData.find((item) => item.id === operatoryId)?.events || [];

      const filterEventsBlockHovering = eventsByOp.filter(
        (event) =>
          event.type !== PMS_EVENT_TYPE.BLOCKED_DOCTOR_HOVERING_EXAM_CLEANING
      );

      const unavailableBlockNumbers = generateBlockNumbers(
        filterEventsBlockHovering
      );

      const availableBlockNumbersByDentist =
        availableBlockNumbersByClinicWorkingHour.filter((blockNumber) => {
          // Check block number after adding dentist duration should not be included in hygienist
          return (
            !unavailableBlockNumbers.includes(blockNumber) &&
            !blockNumbersByHygienistAvailability.includes(blockNumber)
          );
        });

      const groupAvailableBlockNumbers = groupConsecutiveNumber(
        availableBlockNumbersByDentist
      );

      let dentistAvailableBlockNumbersByBackToBack = groupAvailableBlockNumbers
        .filter((item: number[]) => {
          return item.length >= dentistDuration;
        })
        .map((item: number[]) => {
          const blockNumbersBefore = range(
            blockNumbersByHygienistAvailability[0] - dentistDuration,
            blockNumbersByHygienistAvailability[0]
          );

          const blockNumbersAfter = range(
            blockNumbersByHygienistAvailability[
              blockNumbersByHygienistAvailability.length - 1
            ] + 1,
            blockNumbersByHygienistAvailability[
              blockNumbersByHygienistAvailability.length - 1
            ] +
              dentistDuration +
              1
          );

          const isBefore = blockNumbersBefore.every((blockNumber) => {
            return item.includes(blockNumber);
          });

          const isAfter = blockNumbersAfter.every((blockNumber) => {
            return item.includes(blockNumber);
          });

          return {
            isHead: isBefore,
            isTail: isAfter,
            availableBlockNumbers: item,
          };
        });

      let availabilityResult: {
        practitionerId: string;
        operatoryId: string;
        fromTime: string;
        toTime: string;
      }[] = [];

      const dentistWithOp =
        dentistsWithOp.find((tmp) => tmp.id === currentDentist.id) || null;

      if (examPlacement !== EXAM_PLACEMENT.TAIL) {
        const availabilityAtHead = splitDentistAvailabilityByHeadOrTail({
          practitionerId: currentDentist.id,
          operatoryId,
          dentistAvailableBlockNumbersByBackToBack,
          hygienistAvailability,
          type: BOOKING_SPILT_SCHEDULING_TYPE.HEAD,
          dentistDuration,
          dentistWithOp,
        });
        availabilityResult = availabilityResult.concat(availabilityAtHead);
      }

      if (examPlacement !== EXAM_PLACEMENT.HEAD) {
        const availabilityAtTail = splitDentistAvailabilityByHeadOrTail({
          practitionerId: currentDentist.id,
          operatoryId,
          dentistAvailableBlockNumbersByBackToBack,
          hygienistAvailability,
          type: BOOKING_SPILT_SCHEDULING_TYPE.TAIL,
          dentistDuration,
          dentistWithOp,
        });
        availabilityResult = availabilityResult.concat(availabilityAtTail);
      }

      accum = accum.concat(availabilityResult);
    }

    return accum;
  }, []);

  return result;
};

export const filterPractitionerTypeForExamAndCleaning = ({
  practitioners,
  specialist,
}: {
  practitioners?: IMapPractitionerColor[];
  specialist: string;
}) => {
  const result = (practitioners || [])
    .filter((item) => {
      return (
        item.specialist === specialist &&
        item.services?.some((svc) => svc.name === SERVICE_NAME.EXAM_CLEANING)
      );
    })
    .map((item) => ({
      id: item.id,
      colorCode: item.colorCode,
    }));

  return result;
};

export const filterWorkingPractitionerForExamAndCleaning = ({
  selectedPractitioners,
  pmsClinicSchedule,
}: {
  selectedPractitioners: IDebugModeDropdown['practitioners'];
  pmsClinicSchedule?: PMSClinicSchedule;
}) => {
  const scheduleDoctors = pmsClinicSchedule?.doctors || [];

  return selectedPractitioners.filter((item) => {
    const selectedPractitioner = scheduleDoctors.find((doctorSchedule) => {
      return doctorSchedule.id === item.id;
    });

    return (
      selectedPractitioner?.workingHour.start !== null &&
      selectedPractitioner?.workingHour.end !== null
    );
  });
};

//--------Functions for blockUnavailableByDentistNotAvailableForSplitScheduling--------
const checkBlockNumberDentistAfterOrBeforeHygienist = ({
  blockNumber,
  hygienistDuration,
  dentistDuration,
  unavailableDentistBlockNumbersIntersectionByEvent,
  unavailableWorkingDayBlockNumbersIntersectionForDentist,
  type,
  dentistDoctorSchedule,
  isDoctorWorkingDay,
}: {
  type: string;
  blockNumber: number;
  hygienistDuration: number;
  dentistDuration: number;
  unavailableDentistBlockNumbersIntersectionByEvent: number[];
  unavailableWorkingDayBlockNumbersIntersectionForDentist: number[];
  dentistDoctorSchedule: DoctorSchedule[];
  isDoctorWorkingDay: boolean;
}) => {
  let rangeBlocks: number[] = [];

  if (type === BOOKING_SPILT_SCHEDULING_TYPE.HEAD) {
    rangeBlocks = range(blockNumber - dentistDuration, blockNumber);
  } else if (type === BOOKING_SPILT_SCHEDULING_TYPE.TAIL) {
    rangeBlocks = range(
      blockNumber + hygienistDuration!,
      blockNumber + hygienistDuration! + dentistDuration!
    );
  }

  const isBlockNumberBelongNotWorkingDayOfDentist = rangeBlocks.some(
    (block) => {
      return unavailableWorkingDayBlockNumbersIntersectionForDentist.includes(
        block
      );
    }
  );

  const isBlockNumberBelongToEvent =
    isDoctorWorkingDay === false &&
    rangeBlocks.some((block) => {
      return unavailableDentistBlockNumbersIntersectionByEvent.includes(block);
    });

  const isBlockNumberOutSideWorkingHour = dentistDoctorSchedule.every(
    (item) => {
      const workingHourRangeBlocks = calculateRangeBlock(
        item.workingHour.start!,
        item.workingHour.end!
      );

      return rangeBlocks.some(
        (item2) => !workingHourRangeBlocks.includes(item2)
      );
    }
  );

  return (
    isBlockNumberBelongNotWorkingDayOfDentist ||
    isBlockNumberBelongToEvent ||
    isBlockNumberOutSideWorkingHour
  );
};

const calculateUnavailableBlockNumberIntersectionForDoctorsByEventAndAppt = ({
  timeGridData,
  pmsClinicSchedule,
  selectedDoctorIds,
}: any) => {
  const operatoriesByDoctor = pmsClinicSchedule?.doctors
    .filter((dr: any) => selectedDoctorIds.includes(dr.id))
    .map((dr: any) => dr.operatories);

  const uniqOperatoriesByDoctor = uniq(flatten(operatoriesByDoctor));

  const initialOps = uniqOperatoriesByDoctor.map((item: any) => ({
    operatoryId: item.id,
    practitionerId: '',
    name: '',
    blockNumbers: [],
  }));

  const eventByOpForDoctors = calculateUnavailableBlockNumberByEvent({
    timeGridData: cloneDeep(timeGridData),
    selectedDoctorIds,
    pmsClinicSchedule,
  });

  const blackBlockedByOpForDoctors = calculateUnavailableBlockNumberByBlocked({
    timeGridData: cloneDeep(timeGridData),
    selectedDoctorIds,
    pmsClinicSchedule,
  });

  const apptByOpForDoctors = calculateUnavailableBlockNumberByAppt({
    timeGridData: cloneDeep(timeGridData),
    selectedDoctorIds,
    pmsClinicSchedule,
  });

  const groupByOp = groupBy(
    eventByOpForDoctors.concat(
      blackBlockedByOpForDoctors,
      apptByOpForDoctors,
      initialOps
    ),
    'operatoryId'
  );

  const tmpGroupBlockNumbers = Object.keys(groupByOp).map((op) => {
    const tmp = groupByOp[op];
    const blockNumbers = tmp.map((item) => item.blockNumbers);

    return {
      operatoryId: op,
      blockNumbers: uniq(flatten(blockNumbers)),
    };
  });

  const unavailableDentistBlockNumbersByEvent = intersection(
    ...tmpGroupBlockNumbers.map((item) => item.blockNumbers)
  );

  return unavailableDentistBlockNumbersByEvent;
};

export const blockUnavailableByDentistNotAvailableForSplitScheduling = ({
  workingDentists,
  selectedService,
  clinicWorkingHour,
  timeGridData,
  pmsClinicSchedule,
}: {
  selectedPractitioners: IDebugModeDropdown['practitioners'];
  workingDentists: IDebugModeDropdown['practitioners']; // dentist work for exam and cleaning
  selectedService: {
    id: string;
    isSplitScheduling: boolean;
    hygienistDuration?: number;
    doctorDuration?: number;
    duration: number;
    name: string;
  };
  pmsClinicSchedule?: PMSClinicSchedule;
  clinicWorkingHour: { startTime: string; endTime: string };
  timeGridData: {
    events: {
      fromTime: string;
      toTime: string;
      patientName: string;
      operatoryName: string;
      colorCode: string;
      practitionerId: string;
      type: string;
    }[];
    id: string;
    name: string;
  }[];
}) => {
  if (!selectedService.isSplitScheduling) {
    return timeGridData;
  }

  const availableTimeBlockNumbers: number[] = calculateRangeBlock(
    clinicWorkingHour.startTime,
    clinicWorkingHour.endTime
  );

  let availableBlockNumbersByOp = calculateRemainingAvailableBlockNumberByOp({
    timeGridData: cloneDeep(timeGridData),
    availableTimeBlockNumbers,
  });

  const selectedDentistIds = workingDentists.map((item) => item.id);

  const unavailableDentistWorkingDayBlocks = calculateDoctorWorkingDayEvents({
    clinicWorkingHour,
    pmsClinicSchedule,
    selectedDoctorIds: selectedDentistIds,
  });

  const unavailableWorkingDayBlockNumbersIntersectionForDentist =
    calculateWorkingDayUnavailableBlockNumberIntersectionForDoctors({
      doctorIds: selectedDentistIds,
      unavailableWorkingDaysByDoctor: unavailableDentistWorkingDayBlocks,
    });

  const unavailableDentistBlockNumbersIntersectionByEvent =
    calculateUnavailableBlockNumberIntersectionForDoctorsByEventAndAppt({
      timeGridData: cloneDeep(timeGridData),
      pmsClinicSchedule,
      selectedDoctorIds: selectedDentistIds,
    });

  const hygienistDuration = selectedService.hygienistDuration;

  const dentistDuration =
    selectedService.doctorDuration !== 0 ? selectedService.doctorDuration : 1; // 1 for working day

  const selectedDentistsWithOp =
    pmsClinicSchedule?.doctors.filter((doctor: any) =>
      selectedDentistIds.includes(doctor.id)
    ) || [];

  const isDoctorWorkingDay =
    selectedService.isSplitScheduling && selectedService.doctorDuration === 0;

  const filteredUnavailableBlockNumber = availableBlockNumbersByOp.reduce(
    (
      accum: {
        operatoryId: string;
        name: string;
        unavailableTimeBlockNumbers: number[];
      }[],
      currentValue
    ) => {
      const operatoryId = currentValue.operatoryId;

      const isOpAssignToDentists = selectedDentistsWithOp.some((item) =>
        item.operatories.some((op) => op.id === operatoryId)
      );

      if (isOpAssignToDentists) {
        return accum;
      }

      const availableBlockNumbers = currentValue.availableTimeBlockNumbers;

      const filterUnavailableBlockNumberByDentistDuration =
        availableBlockNumbers.filter((blockNumber: number) => {
          const isBlockNumberByUnavailableDentistsForNotWorkingDay =
            selectedService.doctorDuration !== 0 &&
            selectedDentistIds.length === 0;

          const isUnavailableBeforeHygienist =
            checkBlockNumberDentistAfterOrBeforeHygienist({
              type: BOOKING_SPILT_SCHEDULING_TYPE.HEAD,
              blockNumber,
              hygienistDuration: hygienistDuration!,
              dentistDuration: dentistDuration!,
              unavailableWorkingDayBlockNumbersIntersectionForDentist,
              unavailableDentistBlockNumbersIntersectionByEvent,
              dentistDoctorSchedule: selectedDentistsWithOp,
              isDoctorWorkingDay,
            });

          const isUnavailableAfterHygienist =
            checkBlockNumberDentistAfterOrBeforeHygienist({
              type: BOOKING_SPILT_SCHEDULING_TYPE.TAIL,
              blockNumber,
              hygienistDuration: hygienistDuration!,
              dentistDuration: dentistDuration!,
              unavailableWorkingDayBlockNumbersIntersectionForDentist,
              unavailableDentistBlockNumbersIntersectionByEvent,
              dentistDoctorSchedule: selectedDentistsWithOp,
              isDoctorWorkingDay,
            });

          // Check block hygienist should be within dentist working day if doctor working day is set
          const hygienistRangeBlocks = range(
            blockNumber,
            blockNumber + hygienistDuration!
          );

          const isHygienistRangeBlockOutsideWorkingHourDentists =
            hygienistRangeBlocks.some((block) => {
              return unavailableWorkingDayBlockNumbersIntersectionForDentist.includes(
                block
              );
            });

          return (
            (isUnavailableBeforeHygienist && isUnavailableAfterHygienist) ||
            (isDoctorWorkingDay &&
              isHygienistRangeBlockOutsideWorkingHourDentists) ||
            isBlockNumberByUnavailableDentistsForNotWorkingDay
          );
        });

      accum.push({
        operatoryId,
        name: currentValue.name,
        unavailableTimeBlockNumbers:
          filterUnavailableBlockNumberByDentistDuration,
      });

      return accum;
    },
    []
  );

  const result = timeGridData.map((item) => {
    const operatoryId = item.id;

    const unavailableTimeBlockNumbersByOp =
      filteredUnavailableBlockNumber.find(
        (item2: any) => item2.operatoryId === operatoryId
      )?.unavailableTimeBlockNumbers || [];

    if (unavailableTimeBlockNumbersByOp.length === 0) {
      return { ...item };
    }

    const unavailableTimeBlockByGroup = groupConsecutiveNumber(
      unavailableTimeBlockNumbersByOp
    );

    const blockEvents = unavailableTimeBlockByGroup.map((item3: number[]) => {
      let fromTime = item3[0];
      let toTime = item3[0] + 1;

      if (item3.length > 1) {
        toTime = item3[item3.length - 1] + 1;
      }

      return {
        fromTime: convertBlockNumberToTime(fromTime),
        toTime: convertBlockNumberToTime(toTime),
        patientName: '',
        operatoryName: item.name,
        colorCode: '',
        practitionerId: '',
        type: PMS_EVENT_TYPE.BLOCKED,
      };
    });

    item.events = item.events.concat(blockEvents);

    return { ...item };
  });

  return result;
};

export const blockDoctorChairForExamAndCleaning = ({
  timeGridData,
  practitioners,
  clinicWorkingHour,
}: {
  timeGridData: {
    events: {
      fromTime: string;
      toTime: string;
      patientName: string;
      operatoryName: string;
      colorCode: string;
      practitionerId: string;
      type: string;
    }[];
    id: string;
    name: string;
    appointmentDetail?: IPMSAppointment;
  }[];
  practitioners?: IMapPractitionerColor[];
  clinicWorkingHour: {
    startTime: string;
    endTime: string;
  };
}) => {
  const updatedData = timeGridData.map((item) => {
    const practitionersAssignedChair =
      practitioners?.filter((practitioner) =>
        practitioner.operatories?.some((op) => op.id === item.id)
      ) ?? [];

    const isAllDoctor = practitionersAssignedChair.every(
      (practitioner) => practitioner.specialist === SPECIALTY_NAME.DENTIST
    );

    if (practitionersAssignedChair.length === 0 || !isAllDoctor) {
      return item;
    }

    const events = [
      ...item.events,
      {
        fromTime: clinicWorkingHour.startTime,
        toTime: clinicWorkingHour.endTime,
        patientName: '',
        operatoryName: item.name,
        colorCode: '',
        practitionerId: '',
        type: PMS_EVENT_TYPE.BLOCKED_DOCTOR_HOVERING_EXAM_CLEANING,
      },
    ];

    return { ...item, events };
  });
  return updatedData;
};

const checkIsDentistAvailableAtSelectedTime = (
  time: Moment,
  dentistsWithOp: DoctorSchedule[]
) => {
  const availableDoctorInOp = dentistsWithOp.filter(
    (item) => item.operatories.length > 0
  );

  if (availableDoctorInOp.length === 0) return false;

  const isAvailable = availableDoctorInOp.every((item) =>
    time.isBetween(
      moment(item.workingHour.start, 'HH:mm:ss'),
      moment(item.workingHour.end, 'HH:mm:ss'),
      undefined,
      '[]'
    )
  );

  return isAvailable;
};

export const blockUnavailableChairForHygienist = ({
  timeGridData,
  practitioners,
  selectedDebugModeDropdown,
  unitDuration,
  examPlacement,
  clinicWorkingHour,
  pmsClinicSchedule,
}: {
  timeGridData: {
    events: {
      fromTime: string;
      toTime: string;
      patientName: string;
      operatoryName: string;
      colorCode: string;
      practitionerId: string;
      type: string;
    }[];
    id: string;
    name: string;
    appointmentDetail?: IPMSAppointment;
  }[];
  practitioners?: IMapPractitionerColor[];
  selectedDebugModeDropdown: IDebugModeDropdown;
  unitDuration?: number;
  examPlacement?: string;
  clinicWorkingHour: {
    startTime: string;
    endTime: string;
  };
  pmsClinicSchedule?: PMSClinicSchedule;
}) => {
  const isDoctorWorkingDay =
    selectedDebugModeDropdown.service.doctorDuration === 0;

  if (!practitioners || !unitDuration || practitioners.length === 0) {
    return timeGridData;
  }

  const hygienists = practitioners.filter(
    (practitioner) =>
      practitioner.specialist === SPECIALTY_NAME.HYGIENIST &&
      practitioner.services.some((service) =>
        service.name.includes(SERVICE_NAME.EXAM_CLEANING)
      )
  );

  const dentists = practitioners.filter(
    (practitioner) =>
      practitioner.specialist === SPECIALTY_NAME.DENTIST &&
      practitioner.services.some((service) =>
        service.name.includes(SERVICE_NAME.EXAM_CLEANING)
      )
  );

  const flatOpsHygienists = hygienists.map((item) => item.operatories).flat();

  const opAssignedHygienists = uniqBy(flatOpsHygienists, 'id');

  const startBlock = calculateBlocks(clinicWorkingHour.startTime, unitDuration);

  const endBlock = calculateBlocks(clinicWorkingHour.endTime, unitDuration);

  const dentistsWithOp =
    pmsClinicSchedule?.doctors.filter((item) => {
      return dentists.some((item2) => item2.id === item.id);
    }) || [];

  for (let i = 0; i < timeGridData.length; i++) {
    const currentItem = timeGridData[i];

    const isOpAssignToHygienist = opAssignedHygienists.some(
      (op) => op.id === currentItem.id
    );

    if (!isOpAssignToHygienist) {
      continue;
    }

    for (let i = startBlock; i <= endBlock; i++) {
      const minutes = i * unitDuration;

      // 5 MIN TO ENSURE ALL CELL IS CHECKED
      const momentStartTime = moment().startOf('day').add(minutes, 'minutes');
      const momentEndTime = moment()
        .startOf('day')
        .add(minutes, 'minutes')
        .add(MINUTE_OF_A_BLOCK, 'minutes');

      const fromTime = momentStartTime.format('HH:mm:ss');

      const toTime = momentEndTime.format('HH:mm:ss');

      // LOOK FOR DENTIST AVAILABILITY FOR THE CURRENT SCAN TIME
      const result = calculateDentistAvailabilities({
        hygienistAvailability: {
          operatoryId: currentItem.id,
          fromTime,
          toTime,
        },
        clinicWorkingHour,
        dentistsWithOp: dentistsWithOp,
        selectedService: selectedDebugModeDropdown.service,
        timeGridData: cloneDeep(timeGridData) as any,
        examPlacement,
      });

      if (result.length === 0) {
        const toTime = momentStartTime
          .add(unitDuration, 'minutes')
          .format('HH:mm:ss');

        if (toTime > clinicWorkingHour.endTime) {
          continue;
        }

        if (
          isDoctorWorkingDay &&
          checkIsDentistAvailableAtSelectedTime(momentStartTime, dentistsWithOp)
        ) {
          continue;
        }

        // BLOCK THE CELL FROM CURRENT SCAN TIME UP TO UNIT DURATION
        currentItem.events.push({
          fromTime,
          toTime,
          patientName: '',
          operatoryName: currentItem.name,
          colorCode: '',
          practitionerId: '',
          type: PMS_EVENT_TYPE.BLOCKED,
        });
      }
    }
  }

  return timeGridData;
};

export const getDurationRangeArray = (unitDuration: number) => {
  const end = 60;
  return range(unitDuration, end, unitDuration);
};
