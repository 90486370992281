import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import MetaPixelSection from './MetaPixelSection/MetaPixelSection';
import CustomConversionSection from './CustomConverstionSection/CustomConverstionSection';
import { ReactComponent as TriangleIcon } from 'assets/icons/triangle.svg';
import Drawer from 'components/Drawer/Drawer';
import useClinic from 'hooks/useClinic';
import DurationSection from './DurationSection/DurationSection';
import axiosInstance from 'apis/axiosInstance';
import { renderToast } from 'components/Toast/Toast';
import CommonButton from 'components/CommonButton/CommonButton';
import { AxiosResponse } from 'axios';
import GoogleTagManager from './GoogleTagManagerSection/GoogleTagManagerSection';

interface AdvancedTrackingDrawerProps {
  isShowing: boolean;
  setIsShowing: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdvancedTrackingDrawer: FC<AdvancedTrackingDrawerProps> = ({
  isShowing,
  setIsShowing,
}) => {
  const { data, mutate } = useClinic();
  const { control, watch, reset, handleSubmit, formState } = useForm({
    mode: 'all',
    defaultValues: {
      unitDuration: 10,
      slotInterval: 0,
      metaPixelCode: '',
      customScript: '',
      googleTagManagerId: '',
    },
  });

  const [isDurationSectionOpened, setIsDurationSectionOpened] = useState(true);
  const [isTrackingSectionOpened, setIsTrackingSectionOpened] = useState(true);

  useEffect(() => {
    if (!data) return;

    reset({
      unitDuration: data.unitDuration,
      slotInterval: data.slotInterval,
      customScript: data.customScript ? data.customScript : '',
      metaPixelCode: data.metaPixelCode ? data.metaPixelCode : '',
      googleTagManagerId: data.googleTagManagerId
        ? data.googleTagManagerId
        : '',
    });
  }, [data, reset]);

  const onSave = async ({
    slotInterval,
    customScript,
    metaPixelCode,
    googleTagManagerId,
  }: {
    slotInterval: number;
    customScript: string;
    metaPixelCode: string;
    googleTagManagerId: string;
  }) => {
    const promises: Promise<AxiosResponse<any, any>>[] = [];

    if (formState.dirtyFields.slotInterval) {
      promises.push(
        axiosInstance.patch('/clinics/slot-interval', { slotInterval })
      );
    }
    if (formState.dirtyFields.metaPixelCode) {
      promises.push(
        axiosInstance.patch('/clinics/meta-pixel-code', {
          metaPixelCode: metaPixelCode.trim(),
        })
      );
    }
    if (formState.dirtyFields.customScript) {
      promises.push(
        axiosInstance.patch('/clinics/custom-script', {
          customScript: customScript.trim(),
        })
      );
    }
    if (formState.dirtyFields.googleTagManagerId) {
      promises.push(
        axiosInstance.patch('/clinics/google-tag-manager-id', {
          googleTagManagerId: googleTagManagerId.trim(),
        })
      );
    }

    try {
      await Promise.all(promises);
      renderToast({
        type: 'success',
        message: 'Updated settings successfully',
      });
      reset({ slotInterval, customScript, metaPixelCode, googleTagManagerId });
      await mutate();
    } catch (error) {
      renderToast({
        type: 'error',
        message: 'Something went wrong. Please try again later',
      });
    }
  };

  return (
    <Drawer
      title="Advanced"
      isShowing={isShowing}
      setIsShowing={setIsShowing}
      footer={
        <div className="w-full h-6 flex px-2.4 justify-end items-center shadow-primary absolute bottom-0">
          <CommonButton
            className="!min-w-[12rem]"
            onClick={handleSubmit(onSave)}
            disabled={!formState.isDirty}
            isLoading={formState.isSubmitting}
          >
            Save
          </CommonButton>
        </div>
      }
    >
      <div className="pb-2">
        <button
          className="flex gap-x-0.8 items-center cursor-pointer"
          onClick={() => setIsDurationSectionOpened((value) => !value)}
        >
          <TriangleIcon
            className={`${
              isDurationSectionOpened ? '' : '-rotate-90'
            } transition-transform`}
          />
          <span className="uppercase font-bold text-14 leading-[2.1rem] text-dim-grey">
            Duration
          </span>
        </button>
        {isDurationSectionOpened && (
          <div className="flex flex-col gap-y-1.6 mt-1.6">
            <DurationSection
              control={control}
              unitDuration={watch('unitDuration')}
            />
          </div>
        )}

        <button
          className="flex gap-x-0.8 items-center cursor-pointer mt-4"
          onClick={() => setIsTrackingSectionOpened((value) => !value)}
        >
          <TriangleIcon
            className={`${
              isTrackingSectionOpened ? '' : '-rotate-90'
            } transition-transform`}
          />
          <span className="uppercase font-bold text-14 leading-[2.1rem] text-dim-grey">
            Tracking
          </span>
        </button>
        {isTrackingSectionOpened && (
          <div className="mt-1.6 space-y-[3rem]">
            <MetaPixelSection control={control} />
            <GoogleTagManager control={control} />
            <CustomConversionSection control={control} />
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default AdvancedTrackingDrawer;
